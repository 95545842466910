import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    private notifications = new BehaviorSubject<object>([]);
    sharedNotifications = this.notifications.asObservable();
  
    constructor() {}

    addNotificationSuccess( message: string ) {
        this.notifications.next({ type: 'success', addedDateTime: DateTime.now(), message: message });
    }

    addNotificationInfo( message: string ) {
        this.notifications.next({ type: 'info', addedDateTime: DateTime.now(), message: message });
    }

    addNotificationWarning( message: string ) {
        this.notifications.next({ type: 'warning', addedDateTime: DateTime.now(), message: message });
    }

    addNotificationError( message: string ) {
        this.notifications.next({ type: 'error', addedDateTime: DateTime.now(), message: message });
    }

}
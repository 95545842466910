import { Injectable } from '@angular/core';
import { UsersService } from '../users/users.service';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {

    constructor(
        private UsersService: UsersService
    ) { }

    userIsSystemAdmin: boolean = false;
    userIsCustomerAdmin: boolean = false;
    userIsSalesManager: boolean = false;
    userIsSalesStaff: boolean = true;

    async initialize() {
    }

    async isSystemAdmin() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 1 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
    }

    async isCustomerAdmin() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 2 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
            
    }

    async isSalesManager() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 3 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
            
    }

    async isSalesStaff() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 4 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })   
    }

    async isAlSystemAdmin() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 1 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
    }

    async isAlCustomerAdmin() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 1 || data.roleId == 2 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
    }

    async isAlSalesManager() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 1 || data.roleId == 2 || data.roleId == 3 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
    }

    async isAlSalesStaff() {
        return new Promise<boolean>( async (resolve) => {
            this.UsersService.getCurrentUser().then( (data:any) => {

                if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return resolve(false);
                if ( data.roleId == 1 || data.roleId == 2 || data.roleId == 3 || data.roleId == 4 ) return resolve(true)
                return resolve(false)
                
            }).catch( () => {
                return resolve(false);
            })
        })
    }

    async userRoleChecks() {

        // Called from base.
        // Used for auth guards.

        this.UsersService.getCurrentUser().then( (data:any) => {

            if ( data == false || data.roleId === undefined || data.roleId == null || data.roleId < 1 ) return;
    
            if ( data.roleId === 1 ) {
                this.userIsSystemAdmin = true;
                this.userIsCustomerAdmin = true;
                this.userIsSalesManager = true;  
    
            } else if ( data.roleId === 2 ) {
                this.userIsSystemAdmin = false;
                this.userIsCustomerAdmin = true;
                this.userIsSalesManager = true;  
    
            } else if ( data.roleId === 3 ) {
                this.userIsSystemAdmin = false;
                this.userIsCustomerAdmin = false;
                this.userIsSalesManager = true;
            }

        }).catch( () => {
            console.log(`Couldn't do userRoleChecks.`)
        })

    }

}


// GENERAL
import { BehaviorSubject, Observable } from 'rxjs'
import { Injectable, OnInit } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'

// SERVICES
import { AppsettingsService } from '../../app-settings/app-settings.service'
import { GlobalService } from '../../global/global.service'

// INTERFACES
import * as int from '../../../interfaces/users/settings.interface'
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service'

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {

    constructor(
        private AppsettingsService: AppsettingsService,
        private HttpClient: HttpClient,
        private GlobalService: GlobalService,
        private NotificationsService: NotificationsService
    ) {
		this.prepareUserListColumns()
    }

    // Array to hold all of the user settings offline. When a component tries to get a setting
    // we will return a setting, if it exists, from this array. We should not send a request each
    // time we want a setting to the API.
    userSettings:int.UserSetting[] | null = []

    async getSetting( name:string ): Promise<string | number | null> {

        await this.waitForUserSettings()
		if ( !this.userSettings?.length ) return null

        // Loop local array of userSettings that are predownloaded. Check if we have the setting and
        // return that setting if we have it.

        let value:any = null

        for (let i = 0; i < this.userSettings.length; i++) {
            if ( this.userSettings[i].name === name ) {
                value = this.userSettings[i].value
                break
            }
        }

        return value
	}

    async getSettingInt(name: string): Promise<number | null> {

        // Get, or wait, for the user settings to be downloaded.
        const userSettings:int.UserSetting[] = await this.waitForUserSettings()
    
        for ( let i = 0; i < userSettings.length; i++ ) {
            if ( userSettings[i].name === name ) {
                const value = userSettings[i].value
    
                if ( value === null ) return null
    
                // If value is a string, attempt to parse it to an integer
                if (typeof value === 'string') {
                    if ( !isNaN(+value) ) return +value

                } else if (typeof value === 'number') {
                    // If value is already a number, return it directly
                    return +value // Ensures the return is an integer
                }
            }
        }
    
        return null
    }

    async getSettingIntArr( name: string ): Promise<number[] | null> {

        let value:any = await this.getSetting( name )

        // Try to parse the value. We might have an object or array stored in the database.
        try {
            value = JSON.parse( value )
        } catch ( err ) {}

        if ( !Array.isArray( value ) || !value?.length ) return null
    
        return value
    }

    async getSettingStrArr( name: string ): Promise<string[] | null> {

        let value:any = await this.getSetting( name )

        // Try to parse the value. We might have an object or array stored in the database.
        try {
            value = JSON.parse( value )
        } catch ( err ) {}

        if ( !Array.isArray( value ) || !value?.length ) return null
    
        return value
    }

    async getSettingStr( name: string ): Promise<string | null> {

        // Get, or wait, for the user settings to be downloaded
        const userSettings: int.UserSetting[] = await this.waitForUserSettings()
    
        for ( let i = 0; i < userSettings.length; i++ ) {
            if ( userSettings[i].name === name ) {
                const value = userSettings[i].value
    
                if ( value === null ) return null
    
                return value.toString()
            }
        }
    
        return null
    }

    async getSettingBool( name: string ): Promise<boolean | null> {

        // Get, or wait, for the user settings to be downloaded
        const userSettings: int.UserSetting[] = await this.waitForUserSettings()
    
        for ( let i = 0; i < userSettings.length; i++ ) {
            if ( userSettings[i].name === name ) {

                const value = userSettings[i].value
                if ( value === null ) return null
                
                // Convert the number into bool value.
                if ( value === 1 ) return true
                if ( value === 0 ) return false

                return null
            }
        }
    
        return null
    }

    getSettingFromAPI( data:int.GetSetting ): Promise<int.UserSetting> {
		return new Promise((resolve, reject) => {
			const url = this.AppsettingsService.getApiServerUrl() + `users/settings/single`
			this.HttpClient.get(url, {
				params: { settingName: data.settingName }
			}).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			})
		})
	}

    async waitForUserSettings(): Promise<int.UserSetting[]> {

        // If we have the settings locally, return them!
        if ( this.userSettings?.length ) return this.userSettings

        // Wait until we have downloaded them.
        while ( !this.userSettings?.length ) {
            await this.GlobalService.promiseWait(100) // Waits for 100ms before checking again
        }

        return this.userSettings
    }

    async getSettings() {
        
        // This function should be called once on every page refresh. This will download all of the settings
        // from the API and store them locally. To get one specific setting, use getSetting().
        // Call this function if you want to refresh all of the user settings. It might seem unecessary
        // to download all of the settings again. The settings are mostly few and also just text, so it shouldn't
        // matter.

        await this.getSettingsRemote().then( ( userSettings:int.UserSetting[] ) => {
            this.userSettings = userSettings

        }).catch( () => {   
            this.NotificationsService.addNotificationWarning('Kunde inte hämta användarinställningar för applikationen.')
        })
	}

    getSettingsRemote(): Promise<int.UserSetting[]> {
		return new Promise((resolve, reject) => {
			const url = this.AppsettingsService.getApiServerUrlBase() + `users/settings`
			this.HttpClient.get(url).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			})
		})
	}

    updateSetting( data:int.UpdateSetting ): Promise<int.UserSetting> {
		return new Promise((resolve, reject) => {
			const url = this.AppsettingsService.getApiServerUrl() + `users/settings/single`
			this.HttpClient.put(url, {
                settingName: data.settingName,
                settingValue: data.settingValue
			}, { responseType: 'text' }).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			})
		})
	}

    deleteSetting( data:int.DeleteSetting ): Promise<int.UserSetting> {
		return new Promise((resolve, reject) => {
			const url = this.AppsettingsService.getApiServerUrl() + `users/settings/single`
			this.HttpClient.put(url, {
                settingName: data.settingName,
                settingValue: null
			}, { responseType: 'text' }).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			})
		})
	}

	// OBSERVABLE
    private sListColumns: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    public columnList$: Observable<any> = this.sListColumns.asObservable()

    userListVisibleColumns:int.UserListVisibleColumns = {
        erpId: true,
        name: true
    }

    async prepareUserListColumns() {

        // Get, or wait, for the user settings to be downloaded
        const userSettings: int.UserSetting[] = await this.waitForUserSettings()

        let settingNames:string[] = []

        for ( const key in this.userListVisibleColumns ) settingNames.push( key )

        for ( let userSetting of userSettings ) {

            if ( !settingNames.includes( userSetting.name ) ) continue
            
            // Skip the value if it's undefined. This means it doesn't exist in backend.
            if ( userSetting.value === undefined || userSetting.value === null ) continue

            // Set the value
            this.userListVisibleColumns[userSetting.name] = !!+userSetting.value
        }

        // Update the observable
        this.sListColumns.next(this.userListVisibleColumns);

    }

    // Method to update the shared data
    updateCustomerList( data:int.UpdateUserList ) {

        this.userListVisibleColumns[data.name] = data.value

        this.updateSetting({
            settingName: data.name,
            settingValue: data.value
        }).then( (data:any) => {
            console.log(data)

        }).catch( (err:any) => {})

        this.sListColumns.next(this.userListVisibleColumns);
    }
    
    // Method to retrieve the shared data
    getCustomerList(): Observable<any> {
        return this.columnList$
    }

}
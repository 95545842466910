
import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WindowService {

    constructor() {

        fromEvent(window, 'resize').subscribe(() => {
            this.getWindowInnerWidth()
        });
    }

    // OBSERVABLE
    private windowInnerWidthSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public windowInnerWidth$: Observable<any> = this.windowInnerWidthSubject.asObservable();

    resizeTimeout:any

    getWindowInnerWidth() {

        clearTimeout(this.resizeTimeout);

        this.resizeTimeout = setTimeout( () => {
            this.windowInnerWidthSubject.next(window.innerWidth)
        }, 300 )

    }

}

import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class JwtService {

    constructor() { }

    getAccessToken() {

        let accessToken:any = localStorage.getItem('accessToken');

        if ( accessToken === null || accessToken === undefined || accessToken.length < 10 ) {
            return false;
        }

        // Sanitize the token just in case.
        accessToken = accessToken.replace(/(?!\.)(?!_)[^a-z,^A-Z,^0-9,^-]/g, '');

        return accessToken;
    }

    getDecodedToken() {

        const accessToken = this.getAccessToken();

        let decoded:any = '';

        try {
            decoded = jwt_decode(accessToken);
        } catch ( error:any ) {
            return false;
        }

        return decoded;
    }
}
